.inputSearch {
	position: relative;
	margin-bottom: 0.5rem;
	font-variant: tabular-nums;
	display: inline-block;
	width: 100%;
	padding: 4px 11px;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	line-height: 1.5715;
	background-color: #fff;
	border: 1px solid #d9d9d9;
	border-radius: 2px;
	transition: all 0.3s
}

.autocompleteDropdownContainer {
	position: absolute;
	top: 33px;
	left: 16px;
	z-index: 5;
	width: 100%;
}

@media (min-width: 350px) and (max-width: 992px) {
  .modalFormContainer {
    width: 200px;
  }
  .timeContainer {
    margin-right: 10px;
    font-size: 12px;
  }
  .usernameContainer {
    font-size: 16px;
  }
  .textContainer {
    font-size: 14px;
  }
}
