.card {
  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin-bottom: 0.5rem;
}

.defaultFormItem {
  margin: 0;
  padding: 0;
}

.formWrapper {
 width: 100%
}
.customFormContainer {
  display: flex;
  margin-bottom: 0.5rem;
  align-items: center;
}

.buttonActionKey {
  cursor: pointer;
  margin-bottom: 0.3rem;
}

.buttonIconStyle {
  font-size: 16px;
}

.avatarStyle {
  background-color: #000;
  color: #fff;
}

.divider {
  margin: 0.25rem;
  padding: 0;
}
