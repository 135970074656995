.navbar {
  background-color: white;
  padding: 1rem 0;
}
.containerFooterWrapper {
  padding-left: var(--bs-gutter-x, 250px)
}

.balanceFooterWrapper {
float: right;
  display: flex;
  justify-content: flex-end
}

.imageHeaderWrapper {
  margin-right: 0.5rem
}

.headerWrapper {
  display: flex;
  align-items: center
}


.logo {
  height: 32px;
  margin-left: 1rem;
  animation: App-logo-spin infinite 20s linear;
}

.logoName {
  font-family: 'Mukta', sans-serif;
  letter-spacing: -0.025em;
  font-size: 18px;
  color: #fff;
  margin-left: 0.2rem;
}

.navBarContainer {
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  margin-right: 20px;
}

@media (min-width: 992px) {
  .menu {
    display: none;
  }
}
