.textInfoContainer {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 0.3rem;
}

button .ant-btn:hover,
.ant-btn:focus {
  color: unset !important;
}

div .iconsWrapper {
  align-items: center;
  display: flex;
  justify-content: center;
}

.buttonStyle {
  background: none;
  border: none 0;
  box-shadow: none;
}

button .iconDefaultStyle {
  font-size: 18px;
  color: var(--logoColor);
  margin-right: 0.4rem;
}

button .iconDeleteStyle {
  font-size: 18px;
  margin-right: 0.4rem;
}

.textStyle {
  margin-left: 0.3rem;
  color: #1890ff;
}

span .copyIconStyle {
  font-size: 18px;
  color: #828282;
  margin-left: 0.5rem;
}
