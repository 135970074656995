.wrapper {
    position: relative;
    width: 100%;
    height: 100%;
}

.dashboardWrapper {
    height: calc(100% - 88px)
}

.dashboardText {
    color: var(--text-primary);
}

.dashboardButtonWrapper {
    color: #fff;
    cursor: pointer;
    align-items: center;
    display: flex;
    justify-content: center;
}

.dashboardCardInfo {
    height: fit-content
}

.dashboardButton {
    display: block;
    float: right;
    margin: 1.5rem;
}

.dashboardCardWrapper {
    padding-top: 5px;
    padding-bottom: 5px
}

.dashBoardForm {
    width: 100%
}