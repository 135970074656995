p .textInformation {
  color: var(--textColor);
}

.nameStyle {
  text-overflow: 'ellipsis';
	color: var(--textColor);
	overflow: hidden;
  white-space: nowrap;
	width: 100px;
	max-width: 130px;
}

.deviceTypeIcon {
  padding: 2px;
  font-size: 40px;
  align-items: center;
}

.descriptionHideText {
	width: 100px;
	max-width: 130px;
	color: var(--textColor);
	text-overflow: 'ellipsis';
  overflow: hidden;
  white-space: nowrap;
}

span .textStyle {
  display: block;
  align-items: center;
  margin-bottom: 0.3rem;
  cursor: pointer;
}

button .textButtonAdd {
  color: white;
  align-items: center;
  display: flex;
  margin-right: 0.2rem;
}

.rowWrapper {
  display: block;
}

div .iconDefaultStyle {
  cursor: pointer;
  font-size: 20px;
  color: #828282;
  margin-left: 0.5rem;
}

.textAddress {
  margin-left: 0.3rem;
	color: var(--textColor);
}

span .actionCopy {
  display: flex;
  cursor: pointer;
  align-items: center;
}

div .actionWrapper {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

div .actionContainer {
  display: flex;
  align-items: center;
}

.actionDelete {
	color: var(--textColor);
	cursor: pointer;
}

.iconStyle {
  margin-right: 0.2rem;
}

div .actionContainerSpace {
  align-items: center;
  margin-right: 1rem;
}

div .actionMoreInfo {
  align-items: center;
}

.textInfo {
	color: var(--textColor);
}

/* deviceForm */
.containerForm {
  margin-bottom: 0.5rem;
}

.deviceCardWithMarginWrapper {
  margin-right: 10px;
  height: 100%;
}
.deviceCardWrapper {
  margin-right: 10px;
  height: 100%;
}

.googleMapsContainer {
  padding-left: 1rem;
  height: 400px;
  margin-bottom: 2.5rem;
}

.columnDeviceIcon {
  padding: 2px;
  font-size: 18px;
  align-items: center;
}

.columnDeviceTag {
  align-items: center;
  display: inline-flex;
}

.deviceStatus {
  margin-right: 0.3rem;
}

.formItemSelect {
  margin-right: 0.5rem;
  padding: 0;
}

.defaultFormItem {
  margin: 0;
  padding: 0;
}

.customFormContainer {
  display: flex;
  margin-bottom: 0.5rem;
  align-items: center;
}

.buttonCustomField {
  margin-left: 1rem;
  align-items: center;
  display: flex;
}

.customButton {
  margin-left: 1rem;
}
