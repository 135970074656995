.authContainer {
    height: 100%
}

.authWrapper {
    height: 100%;
    justify-content: center;
    align-items: center;
}

.authFormWrapper {
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
}